.actions-toolbar {
    &>.primary {
        .primary {
            color: $white;
        }
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
      color: $white;
    }
}