.cookie-status-message {
    display: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0 0 10px;
    line-height: $font-size-base * 1.57142857143;
}

h1 {
    font-family: $font-family-sans-serif;
}

div.messages {
    &.page {
        .messages {
            padding-top: 1rem;
        }
        .messages {
            + .messages {
                padding-top: 0;
            }
        }
    }
}
